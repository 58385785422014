import { defaultMenuItemProps } from "../_utilities/helpers";
import getImageToolbarItems from "./Image.toolbar";

/**
 * Menu items
 * + Image settings (which includes slideshow show/hide and slideshow urls)
 * 1. Toggle "Autoplay slideshow": bool
 * 2. Toggle "Use same caption": bool
 */

const getSlideshowToolbarItems = (args) => {
  const { breakpoints, autoplay, useSameCaption } = args;

  const isMobile = breakpoints?.bp && (breakpoints?.sm || breakpoints?.xs);

  const items = [
    !isMobile && {
      type: "button",
      props: {
        iconName: "autoplay",
        label: autoplay ? `Don't autoplay slideshow` : `Autoplay slideshow`
      },
      initialVals: { selected: autoplay },
      handlers: {
        onClick: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields.slideshowAutoplay =
              !entity.props.customFields.slideshowAutoplay;
          });
        }
      }
    },
    !isMobile && {
      type: "button",
      props: {
        iconName: "subtitles",
        label: useSameCaption ? "Don't use same caption" : "Use same caption"
      },
      initialVals: { selected: useSameCaption },
      handlers: {
        onClick: (props = defaultMenuItemProps) => {
          props.setEntity((entity) => {
            entity.props.customFields.slideshowUseSameCaption =
              !entity.props.customFields.slideshowUseSameCaption;
          });
        }
      }
    }
  ];

  // NOTE: Add imageItems
  const imageItems = getImageToolbarItems(args);

  // NOTE: Concat image items and items together
  return [].concat(imageItems).concat(items);
};

export default getSlideshowToolbarItems;
