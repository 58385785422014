/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-expressions */
/*
 * Originally from site-components
 */
import React, {
  createContext,
  Fragment,
  useRef,
  useContext,
  useEffect,
  useCallback
} from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useMove } from "@react-aria/interactions";

import { getClasses } from "@washingtonpost/front-end-utils";
import { Button, Icon, PaginationDots } from "@washingtonpost/wpds-ui-kit";
import Play from "@washingtonpost/wpds-assets/asset/play";
import Pause from "@washingtonpost/wpds-assets/asset/pause";
import ArrowLeft from "@washingtonpost/wpds-assets/asset/arrow-left";
import ArrowRight from "@washingtonpost/wpds-assets/asset/arrow-right";

export const defaultOptions = {
  transitionDuration: 1500,
  slideDuration: 7000,
  debouncedDuration: 400
};

const OFF = false;
const DISPLAY = true;

/*
  When controls are hidden pagination dots must be shown and the user can swipe
  to control the slideshow. The controls can support any button icon themes
  */
const displayControlOptions = {
  always: {
    controls: DISPLAY,
    paginationDots: DISPLAY
  },
  displayOnHover: {
    controls: DISPLAY,
    paginationDots: DISPLAY
  },
  off: {
    controls: OFF,
    paginationDots: DISPLAY
  },
  undefined: {
    controls: DISPLAY,
    paginationDots: DISPLAY
  },
  null: {
    controls: DISPLAY,
    paginationDots: DISPLAY
  }
};

const Context = createContext({
  contentId: "",
  value: 0,
  defaultValue: 0,
  onValueChange: () => {},
  autoPlay: true,
  onAutoPlayChange: () => {},
  displayOnHover: false,
  transitionDuration: defaultOptions.transitionDuration,
  slideDuration: defaultOptions.slideDuration,
  total: 1
});

function useCallbackRef(callback) {
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  });

  return React.useCallback((...args) => {
    return callbackRef.current?.(...args);
  }, []);
}

function useDebounceCallback(callback, delay) {
  const handleCallback = useCallbackRef(callback);
  const debounceTimerRef = React.useRef(0);
  React.useEffect(
    () => () => window.clearTimeout(debounceTimerRef.current),
    []
  );
  return React.useCallback(() => {
    window.clearTimeout(debounceTimerRef.current);
    debounceTimerRef.current = window.setTimeout(handleCallback, delay);
  }, [handleCallback, delay]);
}

function useUncontrolledState({ defaultProp, onChange }) {
  const uncontrolledState = React.useState(defaultProp);
  const [value] = uncontrolledState;
  const prevValueRef = React.useRef(value);
  const handleChange = useCallbackRef(onChange);

  React.useEffect(() => {
    if (prevValueRef.current !== value) {
      handleChange(value);
      prevValueRef.current = value;
    }
  }, [value, prevValueRef, handleChange]);

  return uncontrolledState;
}

const useControllableState = ({ prop, defaultProp, onChange = () => {} }) => {
  const [uncontrolledProp, setUncontrolledProp] = useUncontrolledState({
    defaultProp,
    onChange
  });
  const isControlled = prop !== undefined;
  const value = isControlled ? prop : uncontrolledProp;
  const handleChange = useCallbackRef(onChange);

  const setValue = React.useCallback(
    (nextValue) => {
      if (isControlled) {
        const setter = nextValue;
        const thatValue =
          typeof nextValue === "function" ? setter(prop) : nextValue;
        if (thatValue !== prop) handleChange(thatValue);
      } else {
        setUncontrolledProp(nextValue);
      }
    },
    [isControlled, prop, setUncontrolledProp, handleChange]
  );

  return [value, setValue];
};

export const SlideshowRoot = ({
  onValueChange = () => {},
  value: valueProp,
  id = null,
  defaultValue = 0,
  children,
  autoPlay: autoPlayProp,
  onAutoPlayChange = () => {},
  useAutoPlay = false,
  displayControls = "always",
  transitionDuration = defaultOptions.transitionDuration,
  slideDuration = defaultOptions.slideDuration
}) => {
  const [value, setValue] = useControllableState({
    prop: valueProp,
    defaultProp: defaultValue,
    onChange: onValueChange
  });

  const [autoPlay, setAutoPlayValue] = useControllableState({
    prop: autoPlayProp,
    defaultProp: true,
    onChange: onAutoPlayChange
  });

  const [total, setTotal] = useUncontrolledState({
    defaultProp: value,
    onChange: () => {}
  });

  return (
    <Context.Provider
      value={{
        defaultValue,
        contentId: id,
        value,
        onValueChange: setValue,
        autoPlay,
        onAutoPlay: setAutoPlayValue,
        displayOnHover: displayControls === "displayOnHover",
        displayControls,
        transitionDuration,
        slideDuration,
        useAutoPlay,
        total,
        setTotal,
        showNextSlide: () => {},
        showPreviousSlide: () => {},
        toggleAutoPlay: () => {}
      }}
    >
      {children}
    </Context.Provider>
  );
};

SlideshowRoot.propTypes = {
  /** Use auto play feature. Off by default */
  useAutoPlay: PropTypes.bool,
  /** control auto play */
  onAutoPlayChange: PropTypes.func,
  /** used with onAutoPlayChange */
  autoPlay: PropTypes.bool,
  /** content id used for a11y */
  id: PropTypes.string,
  /** callback to respond to slide position state */
  onValueChange: PropTypes.func,
  /** controlled open state used with onValueChange */
  value: PropTypes.number,
  /** should the slider be open on the first slide (on mount) */
  defaultValue: PropTypes.number,
  /** Props.children */
  children: PropTypes.node.isRequired,
  /** Must be in milliseconds */
  slideDuration: PropTypes.oneOf([7000, 3000, 5000, 400, 100]),
  /** Must be in milliseconds */
  transitionDuration: PropTypes.oneOf([1500, 400, 100]),
  /** Based on design spec. "always" will show the controls and pagination dots.
   * "displayOnHover" will show the controls when hovered over. "off" will not
   * show the controls at all but will show the pagination dots. */
  displayControls: PropTypes.oneOf(["always", "displayOnHover", "off"])
};

SlideshowRoot.displayName = "SlideshowRoot";

export const SlideshowTrack = ({
  children,
  aspectRatio,
  ariaLabel = "Slideshow of Placeholder content",
  className,
  paginationLabel
}) => {
  const autoPlayIntervalId = useRef(null);
  const context = useContext(Context);
  const childrenAsArray = React.useMemo(
    () => React.Children.toArray(children),
    [children]
  );

  const slides = React.useMemo(
    () =>
      childrenAsArray.filter(
        (value) => value?.type?.displayName === "SlideshowSlide"
      ),
    [childrenAsArray]
  );

  const controls = React.useMemo(
    () =>
      childrenAsArray.filter(
        (value) => value?.type?.displayName === "SlideshowControls"
      ),
    [childrenAsArray]
  );

  const captions = React.useMemo(
    () =>
      childrenAsArray.filter(
        (value) => value?.type?.displayName === "SlideshowCaption"
      ),
    [childrenAsArray]
  );

  context.showNextSlide = useDebounceCallback(() => {
    context.onValueChange((prev) => {
      if (prev === context.total && (prev + 1) % context.total === 1) {
        return 0;
      }

      return prev + 1;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, defaultOptions.debouncedDuration);

  context.showPreviousSlide = useDebounceCallback(() => {
    context.onValueChange((prev) => {
      return prev === 0 ? context.total : (prev - 1) % context.total;
    });
  }, defaultOptions.debouncedDuration);

  context.toggleAutoPlay = useDebounceCallback(() => {
    context.onAutoPlay((prev) => {
      return !prev;
    });
  }, 0);

  const totalUpSlides = useCallback(() => {
    context.setTotal(slides.length - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides]);

  useEffect(totalUpSlides, [totalUpSlides, context, children]);

  function initAutoPlayConfig() {
    if (context.useAutoPlay && context.autoPlay) {
      autoPlayIntervalId.current = setInterval(
        context.showNextSlide,
        context.slideDuration
      );
    } else {
      clearInterval(autoPlayIntervalId.current);
    }

    return () => {
      clearInterval(autoPlayIntervalId.current);
    };
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initAutoPlayConfig, [context.useAutoPlay, context.autoPlay]);

  return (
    <Fragment>
      <figure
        id={context.contentId}
        role="region"
        aria-label={ariaLabel}
        data-testid="slideshow"
        className={className}
      >
        <TransitionGroup
          className="relative aspect-custom mb-xs transition-opacity duration-400 ease-in-out hover-parent"
          style={
            aspectRatio && {
              "--aspect-width": `${aspectRatio?.split(":")[0]}`,
              "--aspect-height": `${aspectRatio?.split(":")[1]}`
            }
          }
          appear
          enter
          exit
          role="group"
          aria-label={`${context.value + 1} of ${context.total + 1}`}
          id={`${context.contentId}-items`}
        >
          {controls.map((item) => {
            return React.cloneElement(item, {}, null);
          })}

          {slides.map((item, index) => {
            return React.cloneElement(item, {
              id: index,
              className: context.value === index ? "z-1" : ""
            });
          })}
        </TransitionGroup>

        <TransitionGroup appear enter exit component={null}>
          {captions.map((item, index) => {
            return React.cloneElement(
              item,
              {
                id: index
              },
              item.props.children
            );
          })}
        </TransitionGroup>
      </figure>
      {displayControlOptions[context.displayControls]?.paginationDots && (
        <PaginationDots
          label={paginationLabel || ""}
          className="mt-xs ml-auto mr-auto"
          index={context.value + 1 || 1}
          amount={context.total + 1 || 1}
          data-qa="pagination-dots"
        />
      )}
    </Fragment>
  );
};

SlideshowTrack.propTypes = {
  paginationLabel: PropTypes.string,
  as: PropTypes.elementType,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  /** Use aspect ratio: "3:2", "16:9", "1:1", "4:3" or a custom aspect ratio 600:400 */
  aspectRatio: PropTypes.string.isRequired,
  /** called when the play button is pressed */
  onPlay: PropTypes.func,
  /** called when the pause button is pressed */
  onPause: PropTypes.func,
  /** called when the next button is pressed */
  onNext: PropTypes.func,
  /** called when the previous button is pressed */
  onPrevious: PropTypes.func
};

SlideshowTrack.displayName = "SlideshowTrack";

// eslint-disable-next-line react/prop-types
export const SlideshowSlide = ({ id, children, className }) => {
  const context = useContext(Context);

  return (
    <CSSTransition
      key={id}
      timeout={{
        appear: 0,
        enter: 0,
        exit: Number(context.transitionDuration)
      }}
      in={context.value === id}
      unmountOnExit
      classNames={{
        enter: "o-0",
        enterDone: "o-100",
        exit: "o-100",
        exitActive: "o-0"
      }}
      style={{
        "--controlled-duration": `${context.transitionDuration}ms`
      }}
    >
      {React.cloneElement(
        children,
        {
          className: getClasses(className, {
            "absolute top-0 left-0 transition-opacity ease-in-out controlled-duration": true
          }),
          caption: null
        },
        null
      )}
    </CSSTransition>
  );
};

SlideshowSlide.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

SlideshowSlide.displayName = "SlideshowSlide";

// eslint-disable-next-line react/prop-types
export const SlideshowCaption = ({ children, id }) => {
  const context = useContext(Context);

  return (
    <CSSTransition
      key={id}
      timeout={{
        enter: 0,
        exit: Number(context.transitionDuration)
      }}
      appear
      in={context.value === id}
      unmountOnExit
      classNames={{
        appear: "o-0",
        enter: "o-0",
        enterDone: "o-100",
        exit: "o-100",
        exitActive: "o-0 dn"
      }}
      style={{
        "--controlled-duration": `${context.transitionDuration}ms`
      }}
      className="ease-out controlled-duration transition-opacity"
    >
      {children}
    </CSSTransition>
  );
};

SlideshowCaption.propTypes = {
  children: PropTypes.node
};

SlideshowCaption.displayName = "SlideshowCaption";

export const SlideshowControls = ({
  onPlay,
  onPause,
  onNext,
  onPrevious,
  color = "white",
  dataQa,
  className,
  as: Component = "div"
}) => {
  const context = useContext(Context);
  const deltaX = useRef(null);

  // eslint-disable-next-line consistent-return
  const onMoveAction = useDebounceCallback(() => {
    if (!deltaX.current) {
      return null;
    }
    if (Math.sign(deltaX.current) === 1) {
      context.showPreviousSlide();
    } else {
      context.showNextSlide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, defaultOptions.debouncedDuration);

  const { moveProps } = useMove({
    onMove(e) {
      deltaX.current = e.deltaX;
    },
    onMoveEnd() {
      onMoveAction();
    }
  });

  return (
    <Component
      tabIndex={-1}
      data-testid="slideshow-controls"
      className={getClasses("absolute z-2 bottom-0 right-0 w-100 h-100", {
        "hover-child-o-1 o-0 transition-opacity duration-400 ease-in-out":
          context.displayOnHover
      })}
      {...moveProps}
    >
      <CSSTransition
        key="controls"
        timeout={{
          enter: 0,
          exit: defaultOptions.debouncedDuration
        }}
        in={displayControlOptions[context.displayControls]?.controls}
        unmountOnExit
        classNames={{
          enter: "o-0",
          enterDone: "o-100",
          exit: "o-100",
          exitActive: "o-0"
        }}
      >
        <div className="absolute bottom-0 right-0 mb-xs flex">
          <Button
            className={getClasses("mr-xs", {
              [className]: className
            })}
            icon={"center"}
            onClick={(event) => {
              event.preventDefault();
              context.showPreviousSlide();
              onPrevious && onPrevious();
            }}
            {...{
              color,
              "data-qa": dataQa
            }}
            data-testid="slideshow-previous-slide"
            aria-controls={`${context.contentId}-items`}
            aria-label="Previous Slide"
          >
            <Icon>
              <ArrowLeft />
            </Icon>
          </Button>
          {context.useAutoPlay && (
            <Button
              className={getClasses("mr-xs", {
                [className]: className
              })}
              icon={"center"}
              aria-controls={`${context.contentId}-items`}
              aria-label={
                context.autoPlay ? "Pause Slideshow" : "Play Slideshow"
              }
              onClick={(event) => {
                event.preventDefault();
                context.toggleAutoPlay();

                if (context.autoPlay) {
                  onPlay && onPlay();
                } else {
                  onPause && onPause();
                }
              }}
              {...{
                color,
                "data-qa": dataQa
              }}
              data-testid="slideshow-next-slide"
            >
              <Icon>{context.autoPlay ? <Pause /> : <Play />}</Icon>
            </Button>
          )}
          <Button
            className={getClasses("mr-xs", {
              [className]: className
            })}
            icon={"center"}
            onClick={(event) => {
              event.preventDefault();
              context.showNextSlide();
              onNext && onNext();
            }}
            {...{
              color,
              "data-qa": dataQa
            }}
            aria-controls={`${context.contentId}-items`}
            aria-label="Next Slide"
          >
            <Icon>
              <ArrowRight />
            </Icon>
          </Button>
        </div>
      </CSSTransition>
    </Component>
  );
};

SlideshowControls.propTypes = {
  as: PropTypes.elementType,
  /** called when the play button is pressed */
  onPlay: PropTypes.func,
  /** called when the pause button is pressed */
  onPause: PropTypes.func,
  /** called when the next button is pressed */
  onNext: PropTypes.func,
  /** called when the previous button is pressed */
  onPrevious: PropTypes.func,
  /** Color choice */
  color: PropTypes.string,
  /** For end-to-end testing */
  dataQa: PropTypes.string,
  className: PropTypes.string
};

SlideshowControls.displayName = "SlideshowControls";
