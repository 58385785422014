import React, { Fragment, useEffect, useState, useRef } from "react";
import ContentLoader from "react-content-loader";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import get from "lodash.get";
import { getClasses } from "@washingtonpost/front-end-utils";
import { theme } from "@washingtonpost/wpds-ui-kit";
import {
  SlideshowRoot,
  SlideshowSlide,
  SlideshowTrack,
  SlideshowCaption,
  SlideshowControls
} from "~/shared-components/Slideshow";

import { getAspectRatioClasses } from "../_utilities/helpers";

import WapoLazyChild from "~/shared-components/WapoLazyChild";
import { useSandwichLayoutContext } from "~/shared-components/layouts/SandwichLayoutContext";
import Warning from "~/shared-components/Warning";
import { useBreakpoints } from "~/shared-components/BreakpointContext";
import { useFeatureLayoutContext } from "~/shared-components/FeatureLayoutContext";
import { useChainContext } from "~/shared-components/ChainContext";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";

import { IMPLICIT_GRID_INFO } from "~/components/layouts/utilities/grid-helpers";

import getResizedUrl from "~/components/core/elements/image/resize-url";
import { isTouchDevice } from "~/components/utilities/is-touch-device";
import { getDevicePixelRatio } from "~/components/utilities/get-device-pixel-ratio";
import {
  calculateAspectRatio,
  getArtBreakpointSizes,
  getWidthAndHeight
} from "./Image.helpers";
import Caption, {
  getCaptionClasses,
  CAPTION_LINE_HEIGHT,
  CAPTION_PX_PER_CHAR
} from "./Caption";
import { ArtOverlay } from "./Image";
import {
  DEFAULT_SLIDE_DURATION,
  DEFAULT_TRANSITION_DURATION
} from "./Slideshow.helpers";
import getSlideshowToolbarItems from "./Slideshow.toolbar";

import { sendGAClick } from "../../../components/utilities/analytics/analytics";

const getResizedUrls = ({ url, width, height, useHiRes, scalingStrategy }) => {
  let resizedUrl;
  let resized2xUrl;
  let resized4xUrl;
  if (/aspect-fit/.test(scalingStrategy)) {
    resized4xUrl = getResizedUrl(url, { w: 4 * width });
    resized2xUrl = getResizedUrl(url, { w: 2 * width });
    resizedUrl = useHiRes ? resized2xUrl : getResizedUrl(url, { w: width });
  } else {
    // NOTE: else is default behavior
    resized4xUrl = getResizedUrl(url, { w: 4 * width, h: 4 * height });
    resized2xUrl = getResizedUrl(url, { w: 2 * width, h: 2 * height });
    resizedUrl = useHiRes
      ? resized2xUrl
      : getResizedUrl(url, { w: width, h: height });
  }

  return {
    resizedUrl,
    resized2xUrl,
    resized4xUrl
  };
};

const Toolbar = (props) => {
  const { EditableArt } = useAssemblerContext();
  const { useDesktopOrdering } = useChainContext();
  const toolbarItems = getSlideshowToolbarItems({
    ...props,
    useDesktopOrdering
  });
  return (
    <EditableArt menuItems={toolbarItems}>
      <div
        className="absolute top-0 left-0 w-100"
        style={{ paddingBottom: "25%" }}
      />
    </EditableArt>
  );
};

export const SlideshowLoader = ({
  count = 0,
  width,
  height,
  allowCaptions,
  captionLineCountEstimate = 1.0,
  allowDots,
  className,
  style,
  breakpoint = "mx"
}) => {
  count = count > 5 ? 5 : count;
  const captionLineCount = Math.ceil(captionLineCountEstimate);

  // padding
  const p = 8;

  // i = image
  const iW = width;
  const iH = height;
  const iP = allowCaptions || allowDots ? p / 2 : 0;
  const iK = count;

  // c = captions
  const cW = width;
  const cWLast = (captionLineCountEstimate % 1) * width;
  const cH = allowCaptions ? captionLineCount * CAPTION_LINE_HEIGHT : 0;
  const cP = 0;
  const cK = allowCaptions ? "Captions" : "";

  // d = dots
  const dotSpacing = 14;
  const dW = "50%";
  const dH = allowDots ? CAPTION_LINE_HEIGHT : 0; // Why does this work with CAPTION_LINE_HEIGHT
  const dP = allowDots ? 2 * p : 0;
  const dK = allowDots ? "Dots" : "";

  // Dot fudge factor needed for unknown reasons
  let dF = allowDots ? 4 : 0;
  dF = !allowCaptions ? -4 : dF;

  // NOTE: for the overall width height of the svg that ContentLoader creates
  const isMobile = breakpoint === "xs";
  const vbW = width;
  const vbH = iH + iP + cH + cP + dH + dP + dF;
  const vW = isMobile ? "100%" : vbW;
  const vH = isMobile ? `${(100 * height) / width}%` : vbH;

  return (
    <ContentLoader
      speed={2}
      width={vW}
      height={vH}
      viewBox={`0 0 ${vbW} ${vbH}`}
      backgroundColor="#f0f0f0"
      foregroundColor="#e9e9e9"
      className={className}
      style={style}
      uniqueKey={`SlideshowLoader${iK}${cK}${cWLast}${dK}${width}x${height}`}
    >
      {/* image */}
      <rect x="0" y="0" width={iW} height={iH} />
      {/* caption */}

      {cH && (
        <Fragment>
          {new Array(captionLineCount).fill().map((_, i) => {
            const isLast = i + 1 === captionLineCount;
            const w = isLast ? cWLast : cW;
            return (
              <rect
                key={i}
                x="0"
                y={iH + iP + 1 + i * CAPTION_LINE_HEIGHT}
                rx="3"
                ry="3"
                width={w}
                height={CAPTION_LINE_HEIGHT - 2}
              />
            );
          })}
        </Fragment>
      )}

      {/* dots */}
      {dH && (
        <Fragment>
          {new Array(count).fill().map((_, i) => {
            const cx = dW;
            const cy = iH + iP + cH + cP + dP + dF;
            let offset = i + 1 === count / 2 ? 0 : dotSpacing * (i - count / 2);
            // NOTE: The SC centering is off by 1/2 the dot spacing. argh!
            // if count is even and i is odd and offset is 0 then don't compensate
            if (count % 2 === 0 && i % 2 === 0 && offset === 0) {
              offset -= dotSpacing / 2;
            } else {
              offset += dotSpacing / 2;
            }
            let r = i + 1 === count ? 2 : 3;
            r = i === 0 ? 4 : r;
            return (
              <circle
                key={i}
                cx={cx}
                cy={cy}
                r={r}
                transform={`translate(${offset} 0)`}
              />
            );
          })}
        </Fragment>
      )}
    </ContentLoader>
  );
};

SlideshowLoader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  count: PropTypes.number,
  allowCaptions: PropTypes.bool,
  captionLineCountEstimate: PropTypes.number,
  allowDots: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  breakpoint: PropTypes.string
};

const Slideshow = (props) => {
  const {
    images,
    aspectRatioString = "3:2",
    artWidth,
    useHiRes,
    warning,
    allowCaptions,
    charsInLongestCaption,
    useSameCaption,
    overlay,
    linkUrl,
    duration = DEFAULT_SLIDE_DURATION,
    fadeSpeed = DEFAULT_TRANSITION_DURATION,
    maxLoops = 100,
    autoplay,
    dots,
    scalingStrategy,
    lazyLoad,
    isAdmin
  } = props;
  const breakpoints = useBreakpoints();
  const layoutCtx = useSandwichLayoutContext();
  const featureLayoutCtx = useFeatureLayoutContext();
  const layoutVars = {
    ...(layoutCtx?.layoutVars || {}),
    ...(featureLayoutCtx?.layoutVars || {})
  };

  const originalLoopCount = images?.length ? 1 / images.length : 0;

  const aspectRatio = calculateAspectRatio(aspectRatioString);

  // START: Calculate width and height!
  const isFullWidth = artWidth === "full-width";
  const bp = breakpoints.bp || IMPLICIT_GRID_INFO[0].bp;
  const colspan = get(layoutVars, `--c-span-${bp}`);

  const dataSpans = IMPLICIT_GRID_INFO.reduce((acc, info) => {
    acc[info.bp] = get(layoutVars, `--c-span-${info.bp}`);
    return acc;
  }, {});
  const imageBreakpointSizes = getArtBreakpointSizes(dataSpans, artWidth);
  const { width, height } = getWidthAndHeight(
    colspan,
    imageBreakpointSizes[bp],
    aspectRatio,
    isFullWidth
  );
  // END: Calculate width and height!

  const captionLineCountEstimate = allowCaptions
    ? (CAPTION_PX_PER_CHAR * charsInLongestCaption) / width
    : 0;

  const slideshowRef = useRef();
  const [isSkeleton, setIsSkeleton] = useState(lazyLoad);
  const [isFirstImageLoaded, setIsFirstImageLoaded] = useState(false);
  const [isTouch, setIsTouch] = useState(undefined);
  const [devicePixelRatio, setDevicePixelRatio] = useState(1);
  const [autoPlay, setAutoPlay] = useState(false);
  const [showCaptions, setShowCaptions] = useState(allowCaptions);
  const [interaction, setInteraction] = useState(false);
  const [currentValue, setCurrentValue] = useState(0);
  const [loopCount, setLoopCount] = useState(originalLoopCount);
  const slideDuration = duration * 1000;
  const slowTransitionDuration = fadeSpeed * 1000;
  const fastTransitionDuration = 0.15 * 1000;
  const [transitionDuration, setTransitionDuration] = useState(
    slowTransitionDuration
  );
  const [captionMinHeight, setCaptionMinHeight] = useState(
    CAPTION_LINE_HEIGHT * Math.ceil(captionLineCountEstimate)
  );

  const setToManual = () => {
    setAutoPlay(false);
    setInteraction(true);
    setTransitionDuration(fastTransitionDuration);
    setLoopCount(originalLoopCount);
  };

  const handlePlay = () => {
    setToManual();

    // pause triggers when i click play and vice versa,
    // that's with the semantics don't match.
    sendGAClick("onpage", "onpage-click-event", "slideshow-story-nav-pause");
  };

  const setToAuto = () => {
    setAutoPlay(true);
    setInteraction(true);
    setTransitionDuration(slowTransitionDuration);
    // play triggers when i click pause(setToAuto) and vice versa,
    // that's with the semantics don't match.
    sendGAClick("onpage", "onpage-click-event", "slideshow-story-nav-play");
  };

  const incrementValue = () => {
    setCurrentValue((currentValue + 1) % images.length);
  };

  const decrementValue = () => {
    setCurrentValue(currentValue === 0 ? images.length - 1 : currentValue - 1);
  };

  const gotoNext = () => {
    setToManual();
    incrementValue();
    sendGAClick("onpage", "onpage-click-event", "slideshow-story-nav-right");
  };

  const gotoPrevious = () => {
    setToManual();
    decrementValue();
    sendGAClick("onpage", "onpage-click-event", "slideshow-story-nav-left");
  };

  const getRenderedWidthAndHeight = () => {
    const obj = {};
    let src = slideshowRef?.current?.querySelector(`[data-slide]`)?.src;
    if (devicePixelRatio > 1) {
      const srcsets =
        slideshowRef?.current
          ?.querySelector(`[data-slide]`)
          ?.srcset?.split(/\s?,\s?/) || [];
      if (isTouch !== undefined && devicePixelRatio > 1) {
        src = srcsets.reduce((acc, srcset) => {
          if (/(.*)\s2x/i.test(srcset)) acc = `${RegExp.$1}`;
          return acc;
        }, src);
      }
    }
    if (src) {
      if (/&w=(\d+)(?:&h=(\d+))?/.test(src)) {
        if (RegExp.$1) obj.w = Number.parseInt(RegExp.$1, 10);
        if (RegExp.$2) obj.h = Number.parseInt(RegExp.$2, 10);
      }
    }
    return obj;
  };

  // NOTE: This seems not very React-y.
  // Using pure DOM manipulations, this runs all the captions through
  // the caption slot and gets the max height. Then, sets the DOM
  // back to it's original value. A state variable (captionMinHeight)
  // does get modified based on these manipulations. So, at the end,
  // React does re-render with the proper height. Maybe it's not so bad?
  const getCaptionMinHeight = () => {
    if (allowCaptions && !useSameCaption) {
      // NOTE: finding the caption html
      const html = slideshowRef?.current?.querySelector(
        "figure > div:last-of-type > div"
      );
      if (html) {
        let minHeight = 0;
        const originalCaption = html.innerHTML;
        html.style.minHeight = "auto";
        images.forEach((image) => {
          if (image?.caption?.caption) {
            html.innerHTML = image.caption.caption;
            const h = html.getBoundingClientRect().height;
            minHeight = h > minHeight ? h : minHeight;
          }
        });
        html.innerHTML = originalCaption;
        html.style.minHeight = `${minHeight}px`;
        return minHeight;
      }
    }
    return undefined;
  };

  // NOTE: This does two things.
  //  1) Preload an image
  //  2) Figures out if the image is taller than the given aspect ratio.
  const preloadImage = (image) => {
    const { w, h } = getRenderedWidthAndHeight();
    const targetKey = w && h ? `${w}x${h}` : w;
    if (w && !!image && !image.hasOwnProperty(targetKey)) {
      const resizedUrl = getResizedUrl(image.url, w && h ? { w, h } : { w });
      const img = new Image();
      img.src = resizedUrl;
      img.onload = () => {
        image[targetKey] = true;
        image.isTall = img.naturalWidth / img.naturalHeight < aspectRatio;
      };
    }
  };

  const gatherPrevNextImageInfo = () => {
    if (isTouch !== undefined) {
      let nextValue = images.length === 1 ? 0 : currentValue + 1;
      nextValue = nextValue >= images.length ? 0 : nextValue;

      let previousValue = images.length === 1 ? 0 : currentValue - 1;
      previousValue = previousValue < 0 ? images.length - 1 : previousValue;

      // NOTE: Don't bother pre-loading prev if autoPlay is on
      if (autoPlay) previousValue = currentValue;

      [nextValue, previousValue].forEach((value) => {
        preloadImage(images[value]);
      });
    }
  };

  // NOTE: Set touch devices to manual control
  const initSlideshow = debounce(() => {
    setCaptionMinHeight(getCaptionMinHeight());
    setDevicePixelRatio(getDevicePixelRatio());
    if (isTouchDevice()) {
      setIsTouch(true);
      setToManual();
    } else {
      setIsTouch(false);
    }
  }, 250);

  useEffect(() => {
    // NOTE: Turn autoPlay on here instead of initially to give
    // the first image a chance to load before advancing
    if (!interaction) setAutoPlay(!isAdmin && !isTouchDevice() && autoplay);
    initSlideshow();
    // NOTE: B/c of FOUC, adding listener on load
    // Running directly in useEffect() happens fast enough
    // that the captionMinHeight can be miscalculated. Argh!
    window.addEventListener("load", initSlideshow);
    window.addEventListener("resize", initSlideshow);
    return () => window.removeEventListener("resize", initSlideshow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    gatherPrevNextImageInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTouch, devicePixelRatio]);

  useEffect(() => {
    setShowCaptions(allowCaptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowCaptions]);

  useEffect(() => {
    setCaptionMinHeight(getCaptionMinHeight());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captionLineCountEstimate]);

  if (!images || !images.length) return null;

  const buttonControlsColor = "white";

  // return <div className="pb-xs">Son of Flippersaurus Rex</div>;

  const SlideshowImage = ({ image, index }) => {
    const imgRef = useRef();
    const { url } = image;

    // NOTE: startX is to track a swipe
    const [startX, setStartX] = useState();
    const [isTall, setIsTall] = useState(image.isTall);

    const handleTouchStart = (ev) => {
      setStartX(ev?.touches[0]?.clientX);
    };

    const handleTouchEnd = (ev) => {
      const endX = ev?.changedTouches[0]?.clientX;
      if (endX > startX) gotoPrevious();
      else if (endX < startX) gotoNext();
      setStartX();
    };

    const onLoad = () => {
      // NOTE: preload prev/next images
      gatherPrevNextImageInfo();
      if (isSkeleton) {
        // NOTE: This code is invoked here b/c the
        // lazy loader doesn't have a callback
        // and this is a decent proxy.
        setIsSkeleton(false);
        setIsFirstImageLoaded(true);
        setCaptionMinHeight(getCaptionMinHeight());
      }
      const img = imgRef.current;
      const { w, h } = getRenderedWidthAndHeight();
      const targetKey = w && h ? `${w}x${h}` : w;
      image[targetKey] = true;
      image.isTall = img.naturalWidth / img.naturalHeight < aspectRatio;
      setIsTall(image.isTall);
    };

    // NOTE: The SC component only adds these if the SlideshowTrack
    // contains an image only.
    const wrapperClass =
      "image-wrapper absolute top-0 left-0 center transition-opacity ease-in-out controlled-duration";

    const backgroundColorLoaded = /aspect-fit/.test(scalingStrategy)
      ? theme.colors["gray40-static"]
      : "transparent";
    const backgroundColorUnloaded = theme.colors.gray400;
    const wrapperStyle = {
      "--controlled-duration": `${transitionDuration}ms`,
      backgroundColor: isFirstImageLoaded
        ? backgroundColorLoaded
        : backgroundColorUnloaded
    };

    const imageStyle = {
      maxHeight: "100%"
    };

    // NOTE: On some devices, these need to be set or the
    // image won't stretch to fit the slideshow stage
    if (isTall === false) {
      imageStyle.width = "100%";
    } else if (isTall === true) {
      imageStyle.height = "100%";
    }

    const { resizedUrl, resized2xUrl } = getResizedUrls({
      url,
      width,
      height,
      useHiRes,
      scalingStrategy
    });

    return React.createElement(
      linkUrl ? "a" : "div",
      {
        ...(linkUrl ? { href: linkUrl } : {}),
        className: `${linkUrl ? "dib art-link " : ""}${wrapperClass}`,
        style: wrapperStyle,
        onTouchStart: handleTouchStart,
        onTouchEnd: handleTouchEnd
      },
      <Fragment>
        <img
          ref={imgRef}
          src={resizedUrl}
          srcSet={`${resized2xUrl} 2x`}
          className="dib va-m mw-100"
          style={imageStyle}
          alt={""}
          data-slide={index}
          onLoad={onLoad}
        />
        <ArtOverlay {...overlay} />
      </Fragment>
    );
  };

  SlideshowImage.propTypes = {
    image: PropTypes.object,
    index: PropTypes.number
  };

  const captionStyles = captionMinHeight
    ? { minHeight: `${captionMinHeight}px` }
    : {};

  const allowDots = dots === "all" || (dots === "touch" && isTouch);

  const slideshowComponent = (
    <SlideshowRoot
      displayControls={!autoPlay && !interaction ? "always" : "displayOnHover"}
      useAutoPlay={!isTouch}
      autoPlay={autoPlay}
      slideDuration={slideDuration}
      transitionDuration={transitionDuration}
      value={currentValue}
      onValueChange={() => {
        if (autoPlay) {
          setLoopCount(loopCount + 1 / images.length);
          // NOTE: Using toFixed cuz of potential rounding errors
          if (loopCount.toFixed(6) > maxLoops) setToManual();
          else incrementValue();
        }
      }}
    >
      <SlideshowTrack
        aspectRatio={aspectRatioString}
        ariaLabel="Slideshow"
        paginationLabel="Slideshow progress bar"
      >
        {images.map((image, i) => (
          <SlideshowSlide key={i}>
            {/* <img src={getResizedUrl(image.url, { w: width, h: height })} /> */}
            <SlideshowImage image={image} index={i} />
          </SlideshowSlide>
        ))}
        {images.map((image, i) => {
          return showCaptions ? (
            <SlideshowCaption key={i}>
              {/* NOTE: SC fade won't work right w/o this extra nested div. Ugh. */}
              <div>
                {isAdmin || image?.caption?.caption ? (
                  <Caption {...image.caption} style={captionStyles} />
                ) : (
                  <div
                    className={getCaptionClasses(aspectRatio)}
                    style={captionStyles}
                  />
                )}
              </div>
            </SlideshowCaption>
          ) : null;
        })}
        {/* TOOO: Investigate why onPause and onPlay seem to be reversed */}
        <SlideshowControls
          color={buttonControlsColor}
          onPause={setToAuto}
          onPlay={handlePlay}
          onNext={gotoNext}
          onPrevious={gotoPrevious}
        />
      </SlideshowTrack>
    </SlideshowRoot>
  );

  const placeholderComponent = (
    <SlideshowLoader
      count={images.length}
      width={width}
      height={height}
      allowCaptions={allowCaptions}
      captionLineCountEstimate={captionLineCountEstimate}
      allowDots={allowDots}
      className="db mw-mobile"
      breakpoint={bp}
    />
  );

  // NOTE: isArtTinyMode is NOT based on the "Tiny" artSize, but rather
  // on the calcuated width of the art
  const isArtTinyMode = width < 3 * 34 + 2 * 32; // 3-col

  return (
    <div
      className={getClasses(
        `slideshow-container overflow-hidden relative pb-xs dots-${dots}`,
        {
          "is-admin": isAdmin,
          "is-touch-device": isTouch,
          "unknown-touchiness": isTouch === undefined,
          "use-same-caption": allowCaptions && useSameCaption,
          "tiny-mode": isArtTinyMode,
          [getAspectRatioClasses(aspectRatio)]: aspectRatio
        }
      )}
      ref={slideshowRef}
    >
      {isAdmin && Toolbar({ breakpoints, ...props })}
      {warning && !isArtTinyMode && (
        <Warning message={warning} level="warning" />
      )}
      {lazyLoad ? (
        <WapoLazyChild
          offsetTop={500}
          offsetBottom={500}
          throttle={25}
          renderPlaceholder={(lazyRef) => (
            <div ref={lazyRef}>{placeholderComponent}</div>
          )}
        >
          {slideshowComponent}
        </WapoLazyChild>
      ) : (
        <Fragment>{slideshowComponent}</Fragment>
      )}
    </div>
  );
};

Slideshow.propTypes = {
  images: PropTypes.array,
  image: PropTypes.object,
  aspectRatioString: PropTypes.string,
  artWidth: PropTypes.string,
  useHiRes: PropTypes.bool,
  warning: PropTypes.string,
  allowCaptions: PropTypes.bool,
  charsInLongestCaption: PropTypes.number,
  useSameCaption: PropTypes.bool,
  linkUrl: PropTypes.string,
  overlay: PropTypes.object,
  duration: PropTypes.number,
  fadeSpeed: PropTypes.number,
  maxLoops: PropTypes.number,
  autoplay: PropTypes.bool,
  dots: PropTypes.oneOf(["touch", "all"]),
  scalingStrategy: PropTypes.oneOf(["aspect-fit", "aspect-fill"]),
  lazyLoad: PropTypes.bool,
  isAdmin: PropTypes.bool
};

export default Slideshow;
